
import React from 'react'
import { motion } from 'framer-motion';
import { useAppSettings } from '../contexts/AppSettingsContext';

function Loader() {

  const { logo } =
      useAppSettings() || {};
  return (
    <div className="loader-wrapper">
      <section className="loader">
        <motion.div animate={{ scale: 1.2 }}>
          <div>
            <div className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img 
                src="https://admin.bhoomathagroup.com/images/site-images/Bhoomatha-(2)_6cp303abi.webp"
                style={{ width: '250px', height: '100px' }}
                alt="Logo"
                className="logo"
                loading="lazy"
              />
            </div>
          </div>
        </motion.div>
      </section>
    </div>
  )
}

export default Loader

import { createTheme } from "@mui/material/styles";

const ThemeStyle = (theme) => {
  return createTheme({
    typography: {
      h1: {
   
        fontSize: "3rem",
        lineHeight: "3rem",
      },
      h2: {

        fontSize: "2.5rem",
        lineHeight: "2.5rem",
      },
    },
    palette: {
      mode: theme,
      ...(theme === "dark"
        ? {
            background: {
              default: "#121212",
              paper: "#1E1E1E",
            },
            text: {
              primary: "#ffffff",
            },
          }
        : {
            background: {
              default: "#ffffff",
              paper: "#f4f4f4",
            },
            text: {
              primary: "#000000",
            },
          }),
      primary: {
        main: "#8B1C14",
      },
      white: {
        main: "#ffffff",
      },
      secondary: {
        main: "#4C0F0F",
      },
      tertiary: {
        main: "#770707",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            padding: "5px 30px",
            fontSize: "0.95rem",
            fontWeight: "600",
            textTransform: "inherit",
            borderRadius: "100px",
            transition: "background-color 0.3s, color 0.3s, padding 0.3s",
            "&:hover": {
              backgroundColor: theme === "dark" ? "#4C0F0F" : "#8B1C14",
              color: "#ffffff",
            },
          },
        },
      },
    },
  });
};

export default ThemeStyle;

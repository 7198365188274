import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, 
});

const apiService = {
  getCms: () => api.get("/cms"),
  getCmsBySlug: (slug) => api.get(`/cms/${slug}`),
  getSliders: () => api.get("/sliders"),
  getPhotoGalleries: () => api.get("/photo-galleries"),
  getApplicationSettings: () => api.get("/applicationSettings"),
  getWhyChooseUsSettings: () => api.get("/why-choose-us-settings"),
  getTeamCategories: () => api.get("/team-categories"),
  getTestimonials: () => api.get("/hometestimonials/"),
  getAllTestimonials: () => api.get("/testimonials"),
  getWhyChooseBhoomathaTestimonials: () => api.get("/bhoomatha-group-testimonials"),
  getProjectTestimonials: (id) => api.get(`/project-testimonials/${id}`),
  getTeams: (categoryId) => api.get(`/teams/${categoryId}`),
  getDeepamData: () => api.get("/clienteles/deepam"),   
  getFeatureData: () => api.get("/clienteles/our-features"), 
  getDeepamData: () => api.get("/clienteles/deepam"), 
  getFeatureData: () => api.get("/services/our-features"), 
  getServiceDetails: (slug) => api.get(`/service/${slug}`),
  getPostData: () => api.get("/blogs"), 
  getCities: () => api.get("/cities"),
  getSpecialProjects: () => api.get("/specialproject"),
  getCategorywiseProjects: (slug) => api.get(`/projects/category/${slug}`),
  getProjectDetails: (slug) => api.get(`/project/${slug}`),
  getProjectCategories: () => api.get("/project-categories"),
  getVentures: () => api.get('/ventures'),
  getHomeProjects: () => api.get("/home-projects"),
  getProjectAmenities: (projectID) => api.get(`/amenities/${projectID}`),
  getProjects: (filters) => {
    const params = new URLSearchParams(filters).toString();
    return api.get(`/projects?${params}`);
  },
  getProjectLogos: () => api.get("/project-logos"),
  getAllProjects: () => api.get("/projects"),
  getStatistics: () => api.get("/statistics"),
  getCareers: () => api.get("/careers"),
  getResorts: () => api.get("/resorts"),
  getResortDetails: (slug) => api.get(`resorts/${slug}`),
  getProjectPhotoGalleries: () => api.get("/project-photo-galleries"),
  getProjectVideoGalleries: () => api.get("/project-video-galleries"),
  getFaqs: (pagename,id) => api.get(`/faqs/${pagename}/${id}`),
  getallFaqs: () => api.get('/home-faqs'),
  getProjectsForForms:() => api.get("/get-projects-info-for-forms"),
 
};

export default apiService;

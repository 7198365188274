import React, { useEffect, Suspense, useMemo } from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "./contexts/ThemeContext";
import Loader from "./components/loader";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "animate.css";
import "./css/custom.css";
import "./css/responsive.css";

// Lazy load the Router component
const LazyRouter = React.lazy(() => import("./router/router"));

function App() {
  useEffect(() => {
    let lastScrollY = window.scrollY;
    let ticking = false;

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          const currentScrollY = window.scrollY;
          const body = document.body;

          if (currentScrollY > lastScrollY) {
            body.classList.add("scrolling-down");
            body.classList.remove("scrolling-up");
          } else {
            body.classList.add("scrolling-up");
            body.classList.remove("scrolling-down");
          }

          lastScrollY = currentScrollY;
          ticking = false;
        });

        ticking = true;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const loaderStyle = useMemo(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    blockSize: "100vh",
    backgroundColor: "#fff",
  }), []);

  return (
    <ThemeProvider>
      <BrowserRouter>
        <Suspense fallback={<div style={loaderStyle}><Loader /></div>}>
          <LazyRouter />
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
import React, { createContext, useContext, useState, useEffect } from "react";
import apiService from "../services/api";
const AppSettingsContext = createContext();
export const AppSettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState({ logo: "", siteName: "" });
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await apiService.getApplicationSettings();
        if (response.data.success) {
          const data = response.data.data;
          setSettings({
            logo: data["logo"] || "",
            siteName: data["site-name"] || "",
            footerMenu: data["footer-menu"] || "",
            footerText: data["footer-text"] || "",
            copyrightText: data["copyright-text"] || "",
            bannerImage: data["banner-image"] || "",
            primaryPhoneNumber: data["primary-phone-number"] || "",
            secondaryPhoneNumber: data["secondary-phone-number"] || "",
            primaryEmail: data["primary-email"] || "",
            address: data["address"] || "",
            primaryColor: data["primary-color"] || "",
            secondaryColor: data["secondary-color"] || "",
            tertiaryColor: data["tertiary-color"] || "",
            linkColor: data["link-color"] || "",
            fontColor: data["font-color"] || "",
            facebookUrl: data["facebook-url"] || "",
            twitterUrl: data["twitter-url"] || "",
            LinkedInUrl: data["linkedIn-url"] || "",
            instagramUrl: data["instagram-url"] || "",
            youtubeUrl: data["youtube-url"] || "",
            pinterestUrl: data["pinterest-url"] || "",
            tumblrUrl: data["tumblr-url"] || "",
            mediumUrl: data["medium-url"] || "",
            chairmanImage: data["chairman-image"] || "",
            chairmanMessageTitle: data["chairman-message-title"] || "",
            chairmanMessage: data["chairman-message"] || "",
            chairmanName: data["chairman-name"] || "",
            chairmanDesignation: data["chairman-designation"] || "",
            chairmanURL: data["chairman-url"] || "",
            homeProjectsDescription: data["home-projects-description"] || "",
            homeProjectsTitle: data["home-page-title"] || "",
            homeLatestProjectsDescription: data[" home-latest-projects-description"] || "",
            latestProjectTitle: data["latest-project-title"] || "",
            testimonialsTitle: data["testimonials-title-block"] || "",
            imageGalleryTitle: data["image-gallery-title"] || "",
            imageGalleryDescription: data["image-gallery-description"] || "",
            blogTitle: data["blog-title"] || "",
            blogDescription: data["blog-description"] || "",
            statisticsTitle: data["statistics-title"] || "",
            statisticsDescription: data["statistics-description"] || "",
            amnetiestagline:data["amneties-tagline"] || "",
            amnetiestitle:data["amneties-title"] || "",
            whatmakesBhoomathaunique : data["what-makes-bhoomatha-unique"] || "",
            whatmakesBhoomathauniqueDescription : data["what-makes-bhoomatha-unique-description"] || "",
            whyChooseBhoomathaImage: data["why-choose-bhoomatha-image"] || "",
            whyChooseBhoomathaImageAltText: data["why-choose-bhoomatha-image-alt-text"] || "",
            searchBlockTitle: data["search-block-title"] || "",
            searchBlockDescription: data["search-block-description"] || "",
            QrCodeTitle: data["qr-code-title"] || "", 
            ProjectMapTitle: data["project-map-title"] || "",
            featuresBlockTitle: data["features-block-title"] || "",
            featuresBlockDescription: data["features-block-description"] || "",
            newRequirementsFormText: data["new-requirements-form-text"] || "",
            newRequirementsFormImage:  data["new-requirements-form-image"] || "",
            newRequirementsFormImageAltText:  data["new-requirements-form-image-alt-text"] || "",
            videoImage: data["video-image"] || "",
            videoTitle: data["video-title"] || "",
            videoDescription: data["video-description"] || "",
            videoIframe: data["video-iframe"] || "",
            locationIframe: data["location-iframe"] || "",
            welcomeImage1: data["welcome-image-1"] || "",
            welcomeImage2: data["welcome-image-2"] || "",
            welcomeTitle: data["welcome-title"] || "",
            welcomeDescription: data["welcome-description"] || "",
            preBookingTitle: data["pre-booking-title"] || "",
            preBookingDescription: data["pre-booking-description"] || "",
            ogImage: data["og-image"] || "",
            welcomeLinkUrl: data["welcome-link-url"] || "",
            branchOfficeAddress: data["branch-office-address"] || "",
            branchOfficePhoneNumber: data["branch-office-phone-number"] || "",
            branchOfficeAddressTwo: data["branch-office-address-two"] || "",
            branchOfficePhoneNumberTwo: data["branch-office-phone-number-two"] || "",
            contactDescription: data["contact-description"] || "",
            branchOfficeEmail: data["branch-office-email"] || "",
            branchOfficeEmailTwo: data["branch-office-email-two"] || "",
            tawkApiUrl: data["tawk-api"] || "",
            branchOfficeAddressThree: data["branch-office-address-three"] || "",
            branchOfficeEmailThree: data["branch-office-email-three"] || "",
            branchOfficePhoneNumberThree: data["branch-office-phone-number-three"] || "",
            whatsappNumber: data["whatsapp-number"] || "",    
            formSubmissionDescriptionOne: data["form-submission-description-one"] || "",  
            formSubmissionDescriptionTwo: data["form-submission-description-two"] || "", 
            contactFormText: data["contact-form-title"] || "", 
            RecaptchaSiteKey: data["recaptcha-key"] || "",
            Recaptcha: data["show-recaptcha"] || "",
            HomeSeoTitle:data["home-page-seo-title"] || "",
            HomeSeoKeywords: data["home-page-seo-keywords"] || "",
            HomeSeoDescription : data["home-page-seo-description"] || "",
            Resortheading: data["resort-header-title"] || "",
            ResortDescription: data["resort-header-description"] || "",
            ResortContactNumber: data["resort-owner-number"] || "",
            resortblocktitle : data["resort-block-title"] || "",
            resortblockdescription : data["resort-block-description"] || "",
          })

          // Dynamically update CSS variables without repeating defaults
          const root = document.documentElement;
          if (data["primary-color"])
            root.style.setProperty("--clrPrimary", data["primary-color"]);
          if (data["secondary-color"])
            root.style.setProperty("--clrSecondary", data["secondary-color"]);
          if (data["tertiary-color"])
            root.style.setProperty("--clrTertiary", data["tertiary-color"]);
          if (data["link-color"])
            root.style.setProperty("--clrLink", data["link-color"]);
          if (data["font-color"])
            root.style.setProperty("--clrFont", data["font-color"]);
          if (data["border-color"])
            root.style.setProperty("--clrBorder", data["border-color"]);
          if (data["secondary-button-color"])
            root.style.setProperty("--clrSecondaryButton", data["secondary-button-color"]);
          if (data["primary-button-color"])
            root.style.setProperty("--clrPrimaryButton", data["primary-button-color"]);
          if (data["tertiary-button-color"])
            root.style.setProperty("--clrTertiaryButton", data["tertiary-button-color"]);
          if (data["default-button-color"])
            root.style.setProperty("--clrDefaultButton", data["default-button-color"]);
          if (data["text-primary-color"])
            root.style.setProperty("--clrTextPrimary", data["text-primary-color"]);
          if (data["text-secondary-color"])
            root.style.setProperty("--clrTextSecondary", data["text-secondary-color"]);
          if (data["text-tertiary-color"])
            root.style.setProperty("--clrTextTertiary", data["text-tertiary-color"]);
          if (data["icon-primary-color"])
            root.style.setProperty("--clrIconPrimary", data["icon-primary-color"]);
          if (data["icon-secondary-color"])
            root.style.setProperty("--clrIconSecondary", data["icon-secondary-color"]);
        
          if (data["grey-color"])
            root.style.setProperty("--clrGrey", data["grey-color"]);
          if (data["home-banner-color"])
            root.style.setProperty("--clrHomeBannerColor", data["home-banner-color"]); 
          if (data["header-background-color"])
            root.style.setProperty("--clrHeaderBackGroundColor", data["header-background-color"]); 

          
        }
      } catch (error) {
        console.error("Error fetching application settings:", error);
      }
    };
    fetchSettings();
  }, []);
  return (
    <AppSettingsContext.Provider value={settings}>
      {children}
    </AppSettingsContext.Provider>
  );
};
export const useAppSettings = () => useContext(AppSettingsContext);
